
export const POST_GET_IN_TOUCH_REQUEST = 'POST_GET_IN_TOUCH_REQUEST';
export const POST_GET_IN_TOUCH_SUCCESS = 'POST_GET_IN_TOUCH_SUCCESS';
export const POST_GET_IN_TOUCH_FAILURE = 'POST_GET_IN_TOUCH_FAILURE';

export const GET_GET_IN_TOUCH_REQUEST = 'GET_GET_IN_TOUCH_REQUEST';
export const GET_GET_IN_TOUCH_SUCCESS = 'GET_GET_IN_TOUCH_SUCCESS';
export const GET_GET_IN_TOUCH_FAILURE = 'GET_GET_IN_TOUCH_FAILURE';




export const POST_CONTACT_US_REQUEST = 'POST_CONTACT_US_REQUEST';
export const POST_CONTACT_US_SUCCESS = 'POST_CONTACT_US_SUCCESS';
export const POST_CONTACT_US_FAILURE = 'POST_CONTACT_US_FAILURE';

export const GET_CONTACT_US_REQUEST = 'GET_CONTACT_US_REQUEST';
export const GET_CONTACT_US_SUCCESS = 'GET_CONTACT_US_SUCCESS';
export const GET_CONTACT_US_FAILURE = 'GET_CONTACT_US_FAILURE';




export const POST_CAMPUS_AMB_REG_REQUEST = 'POST_CAMPUS_AMB_REG_REQUEST';
export const POST_CAMPUS_AMB_REG_SUCCESS = 'POST_CAMPUS_AMB_REG_SUCCESS';
export const POST_CAMPUS_AMB_REG_FAILURE = 'POST_CAMPUS_AMB_REG_FAILURE';

export const GET_CAMPUS_AMB_REG_REQUEST = 'GET_CAMPUS_AMB_REG_REQUEST';
export const GET_CAMPUS_AMB_REG_SUCCESS = 'GET_CAMPUS_AMB_REG_SUCCESS';
export const GET_CAMPUS_AMB_REG_FAILURE = 'GET_CAMPUS_AMB_REG_FAILURE';

// export const GET_WORKSHOP_REQUEST='GET_WORKSHOP_REQUEST';
// export const GET_WORK