import Aboutsec1 from "../../Components/About/Aboutsec1";
import Aboutsec2 from "../../Components/About/Aboutsec2";
import Aboutsec3 from "../../Components/About/Aboutsec3";
import Aboutsec4 from "../../Components/About/Aboutsec4";

const About = () => {
return(
    <>
    <Aboutsec1/>
    <Aboutsec2/>
    {/* <Aboutsec3/> */}
    <Aboutsec4/>
    </>
)
}
export default About;