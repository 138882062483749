import Allrouts from "./routs/Allrouts";
function App() {
  return (
    <>
    <div>
      <Allrouts/>
    </div>
    </>
  );
}

export default App;
