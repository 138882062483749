import Carousel from "../../Components/Home/Carousel";
import Homesec2 from "../../Components/Home/Homesec2";
import Homesec3 from "../../Components/Home/Homesec3";
import Homesec4 from "../../Components/Home/Homesec4";
import Homesec5 from "../../Components/Home/Homesec5";
const Home =()=>{
return(
    <>
    <Carousel/>
    <Homesec2/>
    <Homesec3/>
    <Homesec4/>
    <Homesec5/>
    </>
)
}
export default Home;